(<template>
  <div class="awarded-block">
    <template v-if="showRegularAwardedBlock">

      <!--  buyer components -->
      <template v-if="userIsNotInterpreter">
        <div class="awarded-block__wrapper">
          <sk-note v-if="noticeText"
                   :text="noticeText"
                   class="awarded-block__notice" />
          <component :is="buyerAwardedComponent">
            <awarded-inter-info v-for="item in contractInfo"
                                :key="item.email"
                                :contract-info="item"
                                class="awarded-block__supplier-info" />
            <awarded-actions />
          </component>
          <admin-withdraw-buttons v-if="showAdminAwardedButtons"
                                  class="awarded-block__buttons" />
          <log-text v-if="$isGodmode() && showSystemLog"
                    :log="systemLog"
                    :show-date="true"
                    class="sk-note awarded-block__demander-note" />
        </div>
      </template>

      <!--  supplier components -->
      <template v-if="userIsInterpreter">
        <component :is="ptIntAwardedComponent">
          <awarded-actions />
        </component>
        <sk-note v-if="cancellationNoteText"
                 :text="cancellationNoteText"
                 class="award-block__cancel-policy" />
        <div v-if="showSystemLog"
             class="awarded-block__supplier-note">
          <log-text :log="systemLog"
                    :show-date="true"
                    class="sk-note" />
        </div>
      </template>
    </template>

    <awarded-inter-info v-for="item in contractInfo"
                        v-else
                        :key="item.email"
                        :contract-info="item"
                        class="awarded-block__wrapper" />
    <direct-availability-section v-if="showAvailabilityTimelineBar" />
  </div>
</template>)

<script>
  import {mapGetters, mapState, mapActions} from 'vuex';
  import CancellationPolicyPrototype from '@/prototypes/CancellationPolicyPrototype';

  export default {
    components: {
      'log-text': () => import('@/components/assignment_components/one_assignment/system_log/LogText'),
      'awarded-actions': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_actions/AwardedActions'),
      'awarded-inter-info': () => import('@/components/assignment_components/one_assignment/awarded_assignment/shared_components/AwardedInterInfo'),
      // INTERPRETER PRICE TEMPLATE AWARDED JOB SECTIONS
      'pt-int-awarded-inperson': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/InterpreterInPerson'),
      'pt-int-awarded-video': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/InterpreterVideo'),
      'pt-int-awarded-videoroom': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/InterpreterVideoroom'),
      'pt-int-awarded-phone': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/InterpreterPhone'),
      // AWARDED JOB SECTIONS BUYER
      'buyer-awarded-inperson': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/BuyerInPerson'),
      'buyer-awarded-video': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/BuyerVideoAndVideoroom'),
      'buyer-awarded-phone': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_awarded/BuyerPhone'),
      'admin-withdraw-buttons': () => import('@/components/assignment_components/one_assignment/awarded_assignment/AdminWithdrawButtons'),
      'direct-availability-section': () => import('@/components/booking_components/parts/direct_booking/DirectAvailabilitySection')
    },
    extends: CancellationPolicyPrototype,
    data() {
      return {
        isProgressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
        contractInfo: ({jobCallDetails}) => jobCallDetails?.contractInfo || []
      }),
      ...mapGetters('OneAssignmentStore', [
        'currentDiscussion'
      ]),
      systemLog() {
        return this.currentDiscussion.keyEvent?.data?.system_log || {};
      },
      showSystemLog() {
        return !!this.systemLog.id;
      },
      jobId() {
        return this.assignmentObj.id || '';
      },
      jobStatus() {
        return this.assignmentObj.status || '';
      },
      assignmentRequirements() {
        return this.assignmentObj.interpretationRequirement || {};
      },
      assignmentType() {
        return this.assignmentRequirements.sessionType;
      },
      noticeText() {
        let template;
        switch (this.assignmentType) {
          case 'video':
            template = this.$gettext('If the interpreter doesn\'t appear on video - please call their phone nr. below or call us at <a href="tel:%{phoneText}" class="js-copy-to-clipboard">%{phoneText}</a>');
            break;
          case 'phone':
            template = this.$gettext('If you are unable to reach the interpreter after the second try - please call us at <a href="tel:%{phoneText}" class="js-copy-to-clipboard">%{phoneText}</a>');
            break;
          case 'in_person':
          case 'videoroom':
            template = this.$gettext('If the interpreter doesn\'t show up on time - please call their phone nr. below or call us at <a href="tel:%{phoneText}" class="js-copy-to-clipboard">%{phoneText}</a>');
            break;
        }

        if (template) {
          return this.$gettextInterpolate(template, {
            phone: this.$getString('domainData', 'supportPhone'),
            phoneText: this.$getString('domainData', 'supportPhoneText')
          });
        }
      },
      // JOB STATUSES
      isUnsuitableJob() {
        return this.jobStatus === 'unsuitable';
      },
      isCancelledJob() {
        return this.jobStatus === 'cancelled';
      },
      isFinishedJob() {
        return this.jobStatus === 'finished';
      },
      isAcceptedJob() {
        return this.jobStatus === 'accepted';
      },
      isInProgressJob() {
        return this.jobStatus === 'in_progress';
      },
      isPublishedJob() {
        return this.jobStatus === 'published';
      },
      isAppliedJob() {
        return this.jobStatus === 'applied';
      },
      isClosedJob() {
        return this.jobStatus === 'closed';
      },
      isRejectedJob() {
        return this.jobStatus === 'rejected';
      },
      isFeedbackJob() {
        return this.jobStatus === 'needsFeedback';
      },
      // SESSION TYPES
      videoroomJob() {
        return this.assignmentType == 'videoroom';
      },
      videoJob() {
        return this.assignmentType == 'video';
      },
      inPersonJob() {
        return this.assignmentType == 'in_person';
      },
      phoneJob() {
        return this.assignmentType == 'phone';
      },
      // COMPONENTS
      buyerAwardedComponent() {
        if (this.inPersonJob) return 'buyer-awarded-inperson';
        if (this.phoneJob) return 'buyer-awarded-phone';
        return 'buyer-awarded-video';
      },
      ptIntAwardedComponent() {
        if (this.inPersonJob) return 'pt-int-awarded-inperson';
        if (this.phoneJob) return 'pt-int-awarded-phone';
        if (this.videoJob) return 'pt-int-awarded-video';
        return 'pt-int-awarded-videoroom';
      },
      showAdminAwardedButtons() {
        return this.$isGodmode() & this.showRegularAwardedBlock;
      },
      showRegularAwardedBlock() {
        return this.isAcceptedJob || this.isInProgressJob;
      },
      showAvailabilityTimelineBar() {
        return this.showRegularAwardedBlock && this.userIsNotInterpreter;
      }
    },
    methods: {
      ...mapActions('PostingStore/DirectBookingStore', [
        'changeSupplier',
      ]),
    },
    mounted() {
      // setTimeoute resolves issue with SSR
      setTimeout(() => {
        this.$store.commit('PostInterpretationStore/setJobInfo', {
          info: this.assignmentObj,
          rootGetters: this.$store.getters,
          rootState: this.$store.state
        });
        this.changeSupplier(this.currentDiscussion.supplierInfo.uid);
      }, 0);
    }
  };
</script>

<style scoped>
  .awarded-block {
    background-color: #fff;
  }

  .awarded-block__wrapper {
    padding: 20px 30px;
  }

  .awarded-block__notice {
    margin-bottom: 15px;
  }

  .awarded-block__demander-note {
    margin-top: 15px;
  }

  .awarded-block__supplier-note {
    padding: 0 30px 20px;
  }

  .awarded-block__buttons {
    margin-top: 15px;
  }

  @media (max-width: 767px) {
    .awarded-block__wrapper {
      padding-right: 15px;
      padding-left: 15px;
    }

    .awarded-block__supplier-note {
      padding: 0 15px 20px;
    }
  }

  .award-block__cancel-policy {
    margin: 0 30px 10px 30px;
  }
</style>
